<template>
  <div>
    <!-- modal vertical center -->
    <b-modal
      id="modal-center"
      centered
      :title="dataModel.title"
      ok-only
      :ok-title="$t('Pages.Notification.Button.Read')"
      @ok="readNotifications"
    >
      <b-badge variant="light-info">
        {{ MyDate.getFormatDateTimeFA(dataModel.created_at) }}
      </b-badge>
      <b-card-text>{{ dataModel.desc }}</b-card-text>
    </b-modal>
    <b-tabs>
      <b-tab>
        <template #title>
          <span v-if="countUnreadPrivate == 0">{{
            $t("Pages.Notification.Title.private_notif")
          }}</span>
          <span v-else>
            {{ $t("Pages.Notification.Title.private_notif") }}
            <b-badge variant="light-warning">
              {{ countUnreadPrivate }}
            </b-badge>
          </span>
        </template>
        <b-row>
          <b-col lg="12" sm="12">
            <b-card v-if="tableDataPrivate" no-body class="card-company-table">
              <b-card-header class="d-flex justify-content-between">
                <h4>
                  {{ $t("Pages.Notification.Title.private_notif") }}
                </h4>
                <b-button
                  variant="primary"
                  @click="seeAllPrivateNotification"
                  >{{ $t("Pages.Notification.Button.see_all") }}</b-button
                >
              </b-card-header>
              <b-table
                :items="tableDataPrivate"
                responsive
                :fields="fields"
                show-empty
                :empty-text="
                  $t('Pages.Notification.Table.no_record_found')
                "
                class="mb-0"
              >
                <template #cell(uuid)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.uuid
                    }}</span>
                  </div>
                </template>

                <template #cell(title)="data">
                  <div class="d-flex flex-column">
                    <span
                      class="font-weight-bolder mb-25"
                      v-if="!data.item.status"
                      >{{ data.item.title }}
                      <b-badge variant="light-warning">
                        {{ $t("Pages.Notification.Table.New") }}
                      </b-badge></span
                    >
                    <span class="mb-25" v-else>{{ data.item.title }}</span>
                  </div>
                </template>

                <template #cell(date)="data">
                  <div class="d-flex align-items-center">
                    <span
                      class="font-weight-bolder mr-1"
                      v-if="!data.item.status"
                      >{{
                        MyDate.getFormatDateTimeFA(data.item.created_at)
                      }}</span
                    >
                    <span class="font-weight-normal mr-1" v-else>{{
                      MyDate.getFormatDateTimeFA(data.item.created_at)
                    }}</span>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="EyeIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      v-b-modal.modal-center
                      @click="showModel(data.item)"
                    />
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    v-if="pages > 1"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination-nav
                      v-show="this.pages > 1"
                      :link-gen="linkGen"
                      :number-of-pages="pages"
                      use-router
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
      <b-tab>
        <template #title>
          <span v-if="countUnreadPublic == 0">{{
            $t("Pages.Notification.Title.public_notif")
          }}</span>
          <span v-else>
            {{ $t("Pages.Notification.Title.public_notif") }}
            <b-badge variant="light-warning">{{ countUnreadPublic }}</b-badge>
          </span>
        </template>
        <b-row>
          <b-col lg="12" sm="12">
            <b-card-header class="d-flex justify-content-between">
              <h4>{{ $t("Pages.Notification.Title.public_notif") }}</h4>
              <b-button variant="primary" @click="seeAllPublicNotification">{{
                $t("Pages.Notification.Button.see_all")
              }}</b-button>
            </b-card-header>
            <b-card v-if="tableDataPublic" no-body class="card-company-table">
              <b-table
                :items="tableDataPublic"
                responsive
                show-empty
                :empty-text="
                  $t('Pages.Notification.Table.no_record_found')
                "
                :fields="fields"
                class="mb-0"
              >
                <template #cell(uuid)="data">
                  <div class="d-flex flex-column">
                    <span class="font-weight-bolder mb-25">{{
                      data.item.group_uuid
                    }}</span>
                  </div>
                </template>

                <template #cell(title)="data">
                  <div class="d-flex flex-column">
                    <span
                      class="font-weight-bolder mb-25"
                      v-if="!data.item.status"
                      >{{ data.item.title }}
                      <b-badge variant="light-warning">
                        {{ $t("Pages.Notification.Table.New") }}
                      </b-badge></span
                    >
                    <span class="mb-25" v-else>{{ data.item.title }}</span>
                  </div>
                </template>

                <template #cell(date)="data">
                  <div class="d-flex align-items-center">
                    <span
                      class="font-weight-bolder mr-1"
                      v-if="!data.item.status"
                      >{{ MyDate.getFormatDate(data.item.created_at) }}</span
                    >
                    <span class="font-weight-normal mr-1" v-else>{{
                      MyDate.getFormatDate(data.item.created_at)
                    }}</span>
                  </div>
                </template>

                <template #cell(actions)="data">
                  <div class="d-flex align-items-center">
                    <feather-icon
                      icon="EyeIcon"
                      size="16"
                      class="mx-1 cursor-pointer"
                      v-b-modal.modal-center
                      @click="showModel(data.item)"
                    />
                  </div>
                </template>
              </b-table>
              <div class="mx-2 mb-2">
                <b-row>
                  <!-- Pagination -->
                  <b-col
                    cols="12"
                    sm="6"
                    v-if="pages > 1"
                    class="d-flex align-items-center justify-content-center justify-content-sm-end"
                  >
                    <b-pagination-nav
                      v-show="this.pages > 1"
                      :link-gen="linkGen"
                      :number-of-pages="pages"
                      use-router
                      class="mb-0"
                    />
                  </b-col>
                </b-row>
              </div>
            </b-card>
          </b-col>
        </b-row>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BCardText,
  BAvatar,
  BTable,
  BImg,
  BButton,
  BLink,
  BPaginationNav,
  BTabs,
  BTab,
  BBadge,
  BCardHeader,
} from "bootstrap-vue";
import MyDate from "@core/components/date/MyDate.vue";

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BAvatar,
    BTable,
    BImg,
    BLink,
    BButton,
    BPaginationNav,
    BTabs,
    BTab,
    BBadge,
    BCardHeader,
  },
  data() {
    return {
      tableDataPrivate: [],
      tableDataPublic: [],
      countUnreadPrivate: 0,
      countUnreadPublic: 0,
      fields: [
        { key: "uuid", label: this.$t("Pages.Notification.Table.UUID") },
        { key: "title", label: this.$t("Pages.Notification.Table.Title") },
        { key: "date", label: this.$t("Pages.Notification.Table.Date") },
        {
          key: "actions",
          label: this.$t("Pages.Notification.Table.Operation"),
        },
      ],
      MyDate,
      pages: 0,
      dataModel: { id: "", title: "", desc: "", created_at: "" },
    };
  },
  created() {
    this.getListNotifications();
  },
  watch: {
    $route(to, from) {
      if (to.query != from.query) {
        this.getListNotifications();
      }
    },
  },
  methods: {
    readNotifications() {
      this.$http
        .post("/api/user/setUserNotificationRead", { id: this.dataModel.id })
        .then((response) => {
          this.$root.$emit("updateNotifications", true);
          this.getListNotifications();
        });
    },
    linkGen(pageNum) {
      return pageNum === 1 ? "?" : `?page=${pageNum}`;
    },
    getListNotifications() {
      this.$http
        .get("/api/user/getUserNotification/private", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((response) => {
          this.tableDataPrivate = response.data.data;
          let countPrivate = 0;
          this.tableDataPrivate.map((item) => {
            if (!item.status) {
              countPrivate += 1;
            }
          });
          this.countUnreadPrivate = countPrivate;
          this.pages = response.data.last_page;
        });

      this.$http
        .get("/api/user/getUserNotification/public", {
          params: {
            page: this.$route.query.page,
          },
        })
        .then((response) => {
          this.tableDataPublic = response.data.data;
          let countPublic = 0;
          this.tableDataPublic.map((item) => {
            if (!item.status) {
              countPublic += 1;
            }
          });
          this.countUnreadPublic = countPublic;
          this.pages = response.data.last_page;
        });
    },
    showModel(data) {
      this.dataModel.title = data.title;
      this.dataModel.desc = data.subtitle;
      this.dataModel.id = data.id;
      this.dataModel.created_at = data.created_at;
    },
    seeAllPrivateNotification() {
      this.$swal({
        title: this.$t("Base.Alert.are_sure"),
        text: this.$t(
          "Pages.Notification.Title.change_status_to_viewed"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Pages.Notification.Button.im_sure"),
        cancelButtonText: this.$t("Pages.Notification.Button.no_care"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .get("/api/user/getUserNotification/private/read-all")
            .then((response) => {
              this.$root.$emit("updateNotifications", true);
              this.getListNotifications();
            });
        }
      });
    },
    seeAllPublicNotification() {
      this.$swal({
        title: this.$t("Base.Alert.are_sure"),
        text: this.$t(
          "Pages.Notification.Title.change_status_to_viewed"
        ),
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("Pages.Notification.Button.im_sure"),
        cancelButtonText: this.$t("Pages.Notification.Button.no_care"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .get("/api/user/getUserNotification/public/read-all")
            .then((response) => {
              this.$root.$emit("updateNotifications", true);
              this.getListNotifications();
            });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
